<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card class="px-4 py-6">
      <div class="d-flex justify-end">
        <v-btn
          class="text-capitalize"
          color="#2E976C"
          style="color: white"
          @click="show = true"
        >
          Tambah Galeri
        </v-btn>
      </div>
      <v-row class="mt-4">
        <v-col
          v-for="i in 8"
          :key="i"
          cols="12"
          sm="3"
        >
          <cover-images />
        </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="show"
      max-width="600"
      persistent
    >
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold">
              Upload Surat
            </span>
            <span
              style="cursor: pointer"
              @click="show = false"
            >
              X
            </span>
          </v-col>
        </v-row>
        <v-row class="mt-8">
          <v-col
            class="pt-4 txt16-gray50"
            cols="12"
            sm="4"
          >
            Judul Folder <span class="symbol-required">*</span>
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            <v-text-field
              v-model="judul"
              type="text"
              outlined
              autocomplete="off"
              dense
              color="#36AC87"
              placeholder="Masukkan judul folder"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n8">
          <v-col
            class="d-flex align-center txt16-gray50"
            cols="12"
            sm="4"
          >
            File Foto
          </v-col>

          <v-col
            class="px-0"
            cols="12"
            sm="8"
          >
            <upload-button
              :is-loading="isLoading"
              :upload-file="uploadedFile"
              @uploadButtonClick="uploadFile"
            />
            <v-file-input
              id="upload-file"
              accept="image/*"
              style="display: none"
              @change="upload"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex justify-end mt-4">
              <v-btn
                color="#2E976C"
                class="mx-2 text-capitalize"
                style="font-weight: bold; color: white"
                @click="modal = true"
              >
                Simpan
              </v-btn>
              <v-btn
                color="#CCCCCC"
                class="mx-2 text-capitalize"
                style="font-weight: bold; color: white"
                @click="show = false"
              >
                Batal
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <modal-konfirmasi
      :show="modal"
      @submit="confirm"
    />
  </v-container>
</template>

<script>
  import CoverImages from './CoverImages.vue'
  import UploadButton from '../komponen/UploadButton.vue'
  import ModalKonfirmasi from '../komponen/modal/ModalKonfirmasi.vue'
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      CoverImages,
      UploadButton,
      ModalKonfirmasi,
    },

    data: () => ({
      modal: false,
      show: false,
      isLoading: false,
      uploadedFile: {
        1: null,
      },
      judul: '',
    }),

    computed: {
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      uploadFile () {
        document.getElementById('upload-file').click()
      },

      confirm (p) {
        // console.log(p)
        if (p === 0) {
          this.modal = false
        } else {
          this.modal = false
          this.submit()
        }
      },

      initialize () {
        // const requestBody = {
        //   page: page,
        //   limit: limit,
        //   searchq: filter,
        // }

        // axios.post('/v2/t/asset', requestBody).then((res) => {
        //   if (res.data.status === 200) {
        //     vm.aset = res.data.data.list
        //     // Pagination:
        //     vm.pageCount = res.data.data.pagination.page_total
        //     vm.itemCount = res.data.data.pagination.item_count
        //     vm.itemTotal = res.data.data.pagination.item_count_total
        //   }
        // })
        //   .catch((e) => {
        //     if (typeof (e.response.data.error) === 'object') {
        //       this.$toast.error(Object.values(e.response.data.error)[0])
        //     } else {
        //       this.$toast.error(e.response.data.error)
        //     }
        //   })
      },

      upload (e) {
        this.isLoading[this.uploadKey] = true
        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('file', e)
        requestBody.append('dest', 'member_photo')

        axios.post(baseCDNurl + '/v1/upload', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            this.isLoading[this.uploadKey] = false
            this.uploadedFile[this.uploadKey] = res.data.data
            this.photoHash = res.data.data.tmp_file_hash
          }
        })
          .catch((e) => {
            this.isLoading[this.uploadKey] = false
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      submit () {
        const files = []
        files.push(this.photoHash)
        const requestBody = {
          title: this.judul,
          plan_date: this.date,
          note: this.keterangan,
          list_file: files,
        }

        axios.post('/v1/admin/working-plan/create', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.judul = ''
            this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.keterangan = ''
            this.photoHash = null
            this.uploadedFile = { 1: null }
            this.$emit('init')
            this.$toast.success(res.data.message)
            this.close()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2B7DEC;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2B7DEC;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
