<template>
  <!-- <div class="d-flex"> -->
  <div>
    <v-img
      src="https://picsum.photos/500"
      max-height="150"
      max-width="250"
      contain
    />
  </div>

  <!-- <div class="d-flex flex-column">
      <div>
        image
      </div>
      <div>
        image
      </div>
    </div> -->
  <!-- </div> -->
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      listImages: [
        'https://picsum.photos/500',
        'https://picsum.photos/500',
        'https://picsum.photos/500',
      ],
    }),

    computed: {
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        // const requestBody = {
        //   page: page,
        //   limit: limit,
        //   searchq: filter,
        // }

        // axios.post('/v2/t/asset', requestBody).then((res) => {
        //   if (res.data.status === 200) {
        //     vm.aset = res.data.data.list
        //     // Pagination:
        //     vm.pageCount = res.data.data.pagination.page_total
        //     vm.itemCount = res.data.data.pagination.item_count
        //     vm.itemTotal = res.data.data.pagination.item_count_total
        //   }
        // })
        //   .catch((e) => {
        //     if (typeof (e.response.data.error) === 'object') {
        //       this.$toast.error(Object.values(e.response.data.error)[0])
        //     } else {
        //       this.$toast.error(e.response.data.error)
        //     }
        //   })
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2B7DEC;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2B7DEC;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
